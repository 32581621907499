import { useEffect, useState } from "react";

const CHECK_INTERVAL = 100; // Check for SmpLibrary every 100ms
const TIMEOUT = 5000; // Timeout after 5 seconds

const useSmartPaySDKLoader = (bundleSrc: string) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {

        // eslint-disable-next-line
        // @ts-ignore
        if (window.SmpLibrary) {
            setIsLoaded(true);
            return;
        }

        const script = document.createElement("script");
        script.src = bundleSrc;
        script.async = true;

        script.onload = () => {
            const waitForLibrary = setInterval(() => {

                // eslint-disable-next-line
                // @ts-ignore
                if (window.SmpLibrary) {
                    setIsLoaded(true);
                    clearInterval(waitForLibrary);
                    console.log("SmartPay SDK Loaded!");

                    const smartPayStyle = document.querySelector('head > link[href*="main.1b3ea652.css"]');
                    if (smartPayStyle) smartPayStyle.setAttribute('href', '/css/smart-pay-fix.css');
                }
            }, CHECK_INTERVAL);

            setTimeout(() => {
                clearInterval(waitForLibrary);

                // eslint-disable-next-line
                // @ts-ignore
                if (!window.SmpLibrary) setError("SmartPay SDK failed to load.");
            }, TIMEOUT);
        };

        script.onerror = () => setError("Failed to load SmartPay SDK");

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return { isLoaded, error };
};

export default useSmartPaySDKLoader;
