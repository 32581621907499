import React , { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function RedirectPage() {
  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');
  const errorCode = searchParams.get('errorCode');
  const redirect = `${process.env.REACT_APP_MOBILEAPP_ID}://payment_redirect?result=${result}${errorCode ? '&errorCodes=' + errorCode : ''}`

  useEffect(() => {
    console.log(`Try to redirect to: ${redirect}`)
    window.location.href = `${redirect}`;
  }, []);

  return (
    <div>
      <h3>Redirecting to your app...{}</h3>
      <h5>If the redirect doesn&apos;t happen automatically, <a href={redirect}>click here</a>.</h5>
    </div>
  )
}
