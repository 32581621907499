import React, { InputHTMLAttributes, useEffect, useRef } from 'react'
import { string, func } from 'prop-types'
import { BronsonReact } from "@vwfs-bronson/bronson-react/dist/types";
import useSmartPaySDKLoader from '../hooks/useSmartPaySDKLoader';

const SCRIPT_ID = 'smartpay';
const SELECTOR_CONTAINER_ID = 'selector-container';


export type SmartPayProps = BronsonReact.Props<{
  sdkUrl: string,
  checkoutToken?: string,
  onError: any,
  onSuccess: any,
  paymentProfileId?: string | null
},
  InputHTMLAttributes<HTMLInputElement>
>
export function SmartPayPayment({
  sdkUrl,
  checkoutToken,
  onError,
  onSuccess,
  paymentProfileId,
}: SmartPayProps) {
  const { isLoaded: scriptLoaded, error } = useSmartPaySDKLoader(sdkUrl);
  const onSuccessRef = useRef(onSuccess)
  const onErrorRef = useRef(onError)
  const containerRef = useRef(null)

  // values (onSuccess, onError) are updated every re-render of the component

  onSuccessRef.current = onSuccess
  onErrorRef.current = onError

  function handleError(errorCode: string, error: object) {
    onErrorRef.current(errorCode, error)
  }

  function handleSuccess(data: object) {
    onSuccessRef.current(data)
  }

  useEffect(() => {
    console.log(error);
  }, [error]);

  useEffect(() => {
    console.log('scriptLoaded:' + scriptLoaded);
    console.log('paymentCheckoutToken: ' + checkoutToken);
    console.log('containerRef.current: ' + containerRef.current);

    if (scriptLoaded && checkoutToken && containerRef.current) {
      // eslint-disable-next-line
      // @ts-ignore
      const app = window.SmpLibrary.SmartPayAppInstance
      console.log('renderWidget');
      app.renderWidget({
        paymentCheckoutToken: checkoutToken,
        container: SELECTOR_CONTAINER_ID,
        paymentFormOnly: true,
        paymentOptionCode: 'CC',
        purpose: 'PAYMENT_TRANSACTION',
        ...(paymentProfileId && { storedPaymentOptionReference: paymentProfileId }),
        successHandler: handleSuccess,
        errorHandler: handleError,
        deleteSpoEnabled: false,
      })
    }
  }, [scriptLoaded, checkoutToken, containerRef])

  return <div id={SELECTOR_CONTAINER_ID} ref={containerRef} data-testid="sdk-smartpay-div"></div>
}

SmartPayPayment.defaultProps = {
  sdkUrl: `${process.env.REACT_APP_SMARTPAY_SDK_URL}?${Math.floor(
    Date.now() / 1000
  )}`,
  /* eslint-disable */
  onError: () => { },
  onSuccess: () => { },
  /* eslint-enable */
}

SmartPayPayment.propTypes = {
  sdkUrl: string,
  onError: func,
  onSuccess: func,
  checkoutToken: string,
  paymentProfileId: string,
}
