import React from "react";
import { SmartPayPayment } from "../../components/SmartPay/Payment";
import { useParams, useSearchParams } from "react-router-dom";

export default function SmartPayPaymentPage() {
  const { checkoutToken } = useParams();
  const [searchParams] = useSearchParams();
  const paymentProfileId = searchParams.get('paymentProfileId');

  const handleError = async (data: any) => {
    //window.location.href = `${process.env.REACT_APP_MOBILEAPP_ID}://payment_redirect?result=error&errorCode=${data}`;
    //window.location.href = `/smartpay/redirect?result=error&errorCode=${data}`;
    window.location.href = `/redirect.html?id=${process.env.REACT_APP_MOBILEAPP_ID}&result=error&errorCode=${data}`;
  }

  const handleSuccess = async (data: any) => {
    if (data.kind === "CALLBACK") {
      //window.location.href = `${process.env.REACT_APP_MOBILEAPP_ID}://payment_redirect?result=success`;
      //window.location.href = `${process.env.REACT_APP_JPM_REDIRECT_URL}?result=success`;
      //window.location.href = `/smartpay/redirect?result=success`;
      window.location.href = `/redirect.html?id=${process.env.REACT_APP_MOBILEAPP_ID}&result=success`;
    }
  }

  //const sdkUrl = 'https://sdk.cons.smartpay.jpmmps.com/light-sdk/smp-bundle.js'
  const sdkUrl = 'https://sdk.cons.smartpay.jpmmps.com/smp-bundle.js'

  return (
    <div>
      <SmartPayPayment
        checkoutToken={checkoutToken}
        onError={handleError}
        onSuccess={handleSuccess}
        sdkUrl={sdkUrl}
        paymentProfileId={paymentProfileId}
      />
    </div>
  )
}
