import React from "react";

export default function InfoPage() {
  return (
    <div>
      <h3>Mobility App Static Assets Version 1.0</h3>
      <h5>Please review the documentation in the repository vwfs-ddm-mobilityapp/static-assets-frontend to learn about
        the directory structures and methods to access the assets.</h5>
    </div>
  )
}
