import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import InfoPage from "./pages/InfoPage";
import SmartPayPaymentPage from "./pages/SmartPayPaymentPage";
import SmartPayProfilePage from "./pages/SmartPayProfilePage";
import SmartPayPaymentCheckoutPage from "./pages/SmartPayPaymentCheckoutPage";
import RedirectPage from "./pages/RedirectPage";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<InfoPage />} />
      <Route path='/smartpay/payment/:checkoutToken' element={<SmartPayPaymentPage />} />
      <Route path='/smartpay/paymentCheckout/:checkoutToken' element={<SmartPayPaymentCheckoutPage />} />
      <Route path='/smartpay/profile/:checkoutToken' element={<SmartPayProfilePage />} />
      <Route path='/smartpay/redirect' element={<RedirectPage />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
